import React from 'react';
import { animated } from 'react-spring';
import { graphql } from 'gatsby';
import {
  Panel, Head, Sub, FlexLayout, Ul, Section,
} from '../../../elements';
import OnlineMenu from '../OnlineMenu';
import PricingTable from './PricingTable';
import Card from '../../../components/Card';
import Content from '../../../components/Content';
import Back from '../../../components/Back';
import AnimatedSvg from '../../../components/AnimatedSvg';
import AppContainer from '../../../components/AppContainer';

// const data = [
//   {
//     component: Leaderboard,
//     title: 'Leaderboard',
//     info: ['Desktop: 720px wide x 90px high', 'Mobile: 320px wide x 50px high'],
//     price: '$1800',
//   },
//   {
//     component: HalfPage,
//     title: 'Half Page (sidebar)',
//     info: ['300px wide x 600px high'],
//     price: '$1590',
//   },
//   {
//     component: Mrec,
//     title: 'Medium Rectangle (sidebar)',
//     info: ['300px wide x 250px high'],
//     price: '$1200',
//   },
//   {
//     component: MrecInline,
//     title: 'Medium Rectangle (in-article)',
//     info: ['300px wide x 250px high'],
//     price: '$1250',
//   },
//   {
//     component: EmailBanner,
//     title: 'Weekly email newsletter banner',
//     info: ['600px wide x 120px high'],
//     price: '$450',
//   },
// ];

const OnlineRates = ({ data: { contentfulPage: page } }) => (
  <AppContainer pageTitle={page.title}>
    <Panel id="content">
      <Back to="/" />
      <OnlineMenu />
      {page.sections
        && page.sections.map((section) => {
          return (
            <Section key={section.id}>
              {section.heading && <Head>{section.heading}</Head>}
              {section.subHeading && <Sub>{section.subHeading}</Sub>}
              {section.items && (
                <FlexLayout style={{ marginBottom: '4rem' }}>
                  {section.items.map(item => (
                    <Card key={item.id} item={item} component={AnimatedSvg} />
                  ))}
                </FlexLayout>
              )}
              {section.table && <PricingTable table={section.table} />}
              {section.unorderedList && (
                <Ul>
                  {section.unorderedList.listItems.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </Ul>
              )}
              <footer>
                {section.content && (
                  <Content
                    className="html-small"
                    content={section.content.childContentfulRichText.html}
                  />
                )}
              </footer>
            </Section>
          );
        })}
    </Panel>
  </AppContainer>
);

export const onlineRatesPageQuery = graphql`
         query onlineRatesPageQuery($id: String!) {
           contentfulPage(id: { eq: $id }) {
             title
             slug
             heading
             sections {
               id
               heading
               subHeading
               content {
                 childContentfulRichText {
                   html
                 }
               }
               table {
                 heading
                 childContentfulTableTableJsonNode {
                   internal {
                     content
                   }
                 }
               }
               unorderedList {
                 listItems
               }
               items {
                 id
                 label
                 infoList
                 extraInfo
                 image {
                  fluid(maxWidth: 1200) {
                    ...GatsbyContentfulFluid
                  }
                  }
                 component
               }
             }
           }
         }
       `;

export default OnlineRates;
