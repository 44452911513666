import React from 'react';
import SecondaryNav from '../../components/SecondaryNav';
import { Sticky } from '../../elements';

const OnlineMenu = ({ location }) => {
  const navItems = [
    {
      to: '/online',
      label: 'About',
    },
    {
      to: '/online/rates-sizes',
      label: 'Rates & Sizes',
    }
  ]
  return (
    <Sticky color="white">
      <SecondaryNav
        items={navItems}
        color={'dark'}
        backgroundColor={'white'}
        hoverColor={'bluegrey'}
        activeColor={'bluegrey'}
        shadow={false}
        size={0.95}
      />
    </Sticky>
  )
}

export default OnlineMenu;
