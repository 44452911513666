import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TableCanOverflow = styled.div`
  display: grid;
  > div {
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

const Table = styled.table`
  border-spacing: 0;
  max-width: 100%;
  width: 100%;
  @media screen and (max-width: 900px) {
    min-width: max-content;
  }
  th,
  td {
    padding: 1rem 2rem 1rem 0;
    text-align: left;
    border-bottom: 1px solid rgba(13, 42, 68, 0.1607843137254902);
  }
`;

const TableFromJson = ({ table }) => {
  // because we don't always know the
  // keys, we parse the stringified
  // json returned by Contentful
  // and pull the table headings
  // from the keys
  const content = table ? JSON.parse(table.internal.content) : {};
  
  const { data, tableHeadings } = content;

  return (
    <TableCanOverflow>
      <div>
        <Table>
          <thead>
            <tr>
              {tableHeadings &&
                tableHeadings.map(
                  ({ text, ...rest }) =>
                    text &&
                    text.length &&
                    !text.startsWith('__') && (
                      <th key={text} {...rest}>
                        {text && text}
                      </th>
                    )
                )}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((key, i) => (
                <tr key={i}>
                  {tableHeadings.map((heading, i) => {
                    const row = key[heading.key];
                    const { text, el, heading: head, ...rest } = row || {};
                    return (
                      text && !text.startsWith('__') ?
                      React.createElement(
                        el || 'td',
                        {
                          key: i,
                          dangerouslySetInnerHTML: {
                            __html: text || '',
                          },
                          ...rest,
                        },
                        null
                      )
                      :
                      null
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </TableCanOverflow>
  );
};

TableFromJson.propTypes = {
  table: PropTypes.shape({
    internal: PropTypes.object.isRequired,
  }),
};

export default TableFromJson;
