import React from 'react';
import styled from 'styled-components';
import TableFromJson from '../../../components/TableFromJson';

const PricingContainer = styled.div`
  margin-bottom: 1rem;
`;

const PricingTable = ({ table }) => (
  <PricingContainer>
    <h2>{table.heading}</h2>
    <TableFromJson overflow table={table.childContentfulTableTableJsonNode} />
  </PricingContainer>
);
export default PricingTable;
